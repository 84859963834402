
import { defineAsyncComponent, PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import LoadingDetail from "./loading-detail.vue";
import OverlayPanel from "primevue/overlaypanel";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import { SHIPMENT_CEK_TARIF } from "@/app/infrastructures/misc/RolePermission";
import { TariffController } from "@/app/ui/controllers/TariffController";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { AccountController } from "@/app/ui/controllers/AccountController";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import { LocationController } from "@/app/ui/controllers/LocationController";

const EmbargoWarning = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/rates-check/component/embargo-warning.vue")
});
const OtopackWarning = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/rates-check/component/otopack-warning.vue")
});

@Options({
  name: "DetailBulk",
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isAnyEmbargo: {
      default: false,
      type: Boolean
    },
    isFullEmbargo: {
      default: false,
      type: Boolean
    },
    isAnyOtopack: {
      default: false,
      type: Boolean
    },
    isNotAllProduct: {
      default: false,
      type: Boolean
    },
    isShowButton: {
      default: false,
      type: Boolean
    },
    goToBook: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isSenderAccount: {
      default: false,
      type: Boolean
    }
  },
  components: {
    LoadingDetail,
    OverlayPanel,
    EmbargoWarning,
    OtopackWarning
  },
  computed: {
    isBookingAble() {
      return checkRolePermission(SHIPMENT_CEK_TARIF.BOOKING);
    }
  }
})
export default class extends Vue {
  titleButton = "+ Booking Pengiriman";

  get isIOSPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor;

    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  }

  get detailData() {
    return TariffController.detailTariff;
  }

  get isAccountCountryOutsideID() {
    return AccountController.accountData.account_type_detail.countryCode.toLowerCase() !== 'id';
  }

  get detailDistrict() {
    return LocationController.districtDetailTariff;
  }
  get isInternal() {
    return AccountController.accountData.isInternalAccount;
  }
  get isInternalOutsideID() {
    return this.isInternal && this.detailDistrict.countryCode.toLowerCase() === 'my';
  }

  formatPrice(number: number) {
    return `${formatPrice(number, "", this.isAccountCountryOutsideID || this.isInternalOutsideID ? "MYR": "IDR")}`
  }

  get isLoading() {
    return TariffController.isLoadingDetail;
  }

  isActiveTooltip = false;
  toggleTooltip(event: any) {
    this.isActiveTooltip = !this.isActiveTooltip;
    const refs: any = this.$refs;
    refs["opTooltip"].toggle(event);
  }

  refs: any = {};
  onOpenTooltipPromo(event: any, index: number) {
    const refs: any = this.$refs;
    refs[`infoPromo${index}`].toggle(event);
  }

  formatDateWithoutTime(value: string) {
    return formatDateWithoutTime(value);
  }

  quantifiers(quanti: string) {
    let newQuantifiers = ""
    for (const el of this.detailData?.listDiscount) {
      newQuantifiers +=  `${el.pdcQuantifier},`
    }
    const quantiExist = newQuantifiers.split(",").filter((item: string) => item === quanti);
    return quantiExist.length;
  }
}
