
import { Vue, Options } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { PropType } from "vue";

@Options({
  name: "LoadingDetail",
  components: {
    Skeleton
  },
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    }
  }
})
export default class extends Vue {}
