import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/app/ui/assets/svg/x.svg'


const _hoisted_1 = { class: "relative w-5/12 h-screen flex flex-col justify-between bg-white" }
const _hoisted_2 = { class: "border-b border-gray-lp-400 py-4" }
const _hoisted_3 = { class: "px-8 inline-flex justify-between w-full" }
const _hoisted_4 = { class: "px-4 py-4 overflow-auto h-screen" }
const _hoisted_5 = { class: "px-4 pt-2" }
const _hoisted_6 = { class: "flex flex-row my-4" }
const _hoisted_7 = { class: "w-1/2 flex flex-col" }
const _hoisted_8 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_9 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_10 = { class: "w-1/2 flex flex-col" }
const _hoisted_11 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_12 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_13 = { class: "flex flex-col my-4" }
const _hoisted_14 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_15 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_16 = { class: "flex flex-col my-4" }
const _hoisted_17 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_18 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_19 = { class: "flex flex-col my-4" }
const _hoisted_20 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_21 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_22 = { class: "border-t border-gray-lp-400 px-4 pt-2" }
const _hoisted_23 = { class: "flex flex-col my-4" }
const _hoisted_24 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_25 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_26 = { class: "flex flex-col my-4" }
const _hoisted_27 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_28 = { class: "my-2 mr-4 text-gray-lp-300" }
const _hoisted_29 = { class: "flex flex-col my-4" }
const _hoisted_30 = { class: "font-semibold text-gray-lp-300" }
const _hoisted_31 = { class: "my-2 mr-4 text-gray-lp-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_Skeleton, {
          height: "1.3rem",
          width: "10rem"
        }),
        _createVNode("img", {
          src: _imports_0,
          class: "w-4 cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
          alt: "x"
        })
      ])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_Skeleton, {
                height: "1.3rem",
                width: "7.5rem"
              })
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode(_component_Skeleton, {
                height: "1.3rem",
                width: "10.5rem"
              })
            ])
          ]),
          _createVNode("div", _hoisted_10, [
            _createVNode("div", _hoisted_11, [
              _createVNode(_component_Skeleton, {
                height: "1.3rem",
                width: "7.5rem"
              })
            ]),
            _createVNode("div", _hoisted_12, [
              _createVNode(_component_Skeleton, {
                height: "1.3rem",
                width: "10.5rem"
              })
            ])
          ])
        ]),
        _createVNode("div", _hoisted_13, [
          _createVNode("div", _hoisted_14, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "9rem"
            })
          ]),
          _createVNode("div", _hoisted_15, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "15.5rem"
            })
          ])
        ]),
        _createVNode("div", _hoisted_16, [
          _createVNode("div", _hoisted_17, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "8rem"
            })
          ]),
          _createVNode("div", _hoisted_18, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "12.5rem"
            })
          ])
        ]),
        _createVNode("div", _hoisted_19, [
          _createVNode("div", _hoisted_20, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "9rem"
            })
          ]),
          _createVNode("div", _hoisted_21, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "10.5rem"
            })
          ])
        ])
      ]),
      _createVNode("div", _hoisted_22, [
        _createVNode("div", _hoisted_23, [
          _createVNode("div", _hoisted_24, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "8.5rem"
            })
          ]),
          _createVNode("div", _hoisted_25, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "12.5rem"
            })
          ])
        ]),
        _createVNode("div", _hoisted_26, [
          _createVNode("div", _hoisted_27, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "12.5rem"
            })
          ]),
          _createVNode("div", _hoisted_28, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "100%"
            })
          ])
        ]),
        _createVNode("div", _hoisted_29, [
          _createVNode("div", _hoisted_30, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "13rem"
            })
          ]),
          _createVNode("div", _hoisted_31, [
            _createVNode(_component_Skeleton, {
              height: "1.3rem",
              width: "100%"
            })
          ])
        ])
      ])
    ])
  ]))
}